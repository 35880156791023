import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { withRouter, useParams } from "react-router-dom";
import axios from "axios";

import { withFirebase } from "../../firebase";
import Loading from "../../components/Loading";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import Paper from "@material-ui/core/Paper";
import { filtredMenu } from "./menuSort.js";

import Tabs from "./tabs";

const MenuSort = () => {
	const classes = useStyles();
	const { brandId } = useParams();

	const [categories, setCategories] = useState(null);
	const [products, setProducts] = useState(null);
	const shops = useSelector((state) => state.shops.shops);

	const activeBrand = shops
		.flatMap((shop) => shop.brands)
		.find((brand) => brand.id === brandId && brand.isActive);

	const iikoAccount = activeBrand?.apiSettings.iikoTransport.account;
	const categoriesOrder = activeBrand?.category.categoriesOrder || [];
	const hiddenCategories = activeBrand?.category.hiddenCategories || [];
	const hiddenProducts = activeBrand?.category.hiddenProducts || [];

	useEffect(() => {
		let isMounted = true;
		setCategories(null);
		setProducts(null);

		if (iikoAccount) {
			// axios
			// 	.get(`https://e-api.com.ua/api/v1/iikoTransport/${iikoAccount}/menu`)
			axios
				.get(
					`https://e-api.ithinkers.fun/api/v1/iikoTransport/${iikoAccount}/menu`
				)
				.then((response) => {
					if (isMounted) {
						let filteredCategories = response.data;

						const filteredData = filtredMenu(
							filteredCategories,
							activeBrand,
							[]
						);
						// const parentCategories =
						// 	activeBrand.category?.parentCategories?.filter(
						// 		(item) => item && item.trim() !== ""
						// 	);

						// let filteredCategories = response.data.categories;

						// if (parentCategories && parentCategories.length > 0) {
						// 	filteredCategories = filteredCategories.filter((category) =>
						// 		parentCategories.includes(category.parentGroup)
						// 	);
						// }

						// const categoryIds = filteredCategories.map(
						// 	(category) => category.id
						// );

						// const filteredProducts = response.data.products.filter((product) =>
						// 	categoryIds.includes(product.parentGroup)
						// );

						setCategories(filteredData.filtredCategories);
						setProducts(filteredData.filtredProducts);
					}
				})
				.catch((error) => {
					console.error("Failed to fetch menu:", error);
				});

			return () => {
				isMounted = false; // Устанавливаем isMounted в false, когда компонент размонтируется
			};
		}
	}, [iikoAccount, activeBrand]);

	return (
		<Grid container spacing={3}>
			<Grid item xs={12}>
				<Paper className={classes.modal}>
					<div className={classes.wrapper}>
						<div className={classes.title}>Сортировка меню</div>
						<Tabs
							categories={categories}
							products={products}
							categoriesOrder={categoriesOrder}
							hiddenCategories={hiddenCategories}
							hiddenProducts={hiddenProducts}
						/>
					</div>
				</Paper>
			</Grid>
		</Grid>
	);
};

const useStyles = makeStyles({
	modal: {
		width: "100%",
		height: "auto",
		display: "flex",
		justifyContent: "center",
		alignItems: "center",
		background: "white",
		borderRadius: 10,
	},
	wrapper: {
		width: "100%",
		padding: 16,
		paddingTop: 24,
		paddingBottom: 16,
		boxSizing: "border-box",
	},
	title: {
		fontSize: 21,
		fontFamily: "Roboto",
		fontWeight: "bold",
		lineHeight: 2,
		letterSpacing: "0.003em",
	},
});

export default withRouter(withFirebase(MenuSort));
