import React, { useEffect, useState } from "react";

import Loading from "../../../components/Loading";
import { trim } from "../other";

import {
	EyeIcon,
	EyeOffIcon,
} from "../../products/categories/listCategories/icons";

import useStyles from "../categories/styles";
import LinkIcon from "@material-ui/icons/Link";

const ProductsSort = (props) => {
	const { categories, products, hiddenProducts, handleToggleHidden } = props;

	const classes = useStyles();

	const [copiedId, setCopiedId] = useState(null);
	const [activeCategory, setActiveCategory] = useState(
		categories?.length > 0 ? categories[0].id : null
	);

	const selectCategory = (categoryId) => {
		setActiveCategory(categoryId);
	};

	useEffect(() => {
		!activeCategory &&
			categories?.length > 0 &&
			setActiveCategory(categories[0].id);
	}, [categories]);

	const copyText = async (id) => {
		try {
			await navigator.clipboard.writeText(id);
			setCopiedId(id);
			setTimeout(() => setCopiedId(null), 2000);
		} catch (err) {
			console.error("Failed to copy: ", err);
		}
	};

	return (
		<div className={classes.prodcutsContainer}>
			<div className={classes.listCategories}>
				{categories.map((category) => (
					<div
						key={category.id}
						className={`${classes.category} ${
							activeCategory === category.id ? classes.activeCategory : ""
						}`}
						onClick={() => selectCategory(category.id)}
					>
						{category.name}
					</div>
				))}
			</div>
			<div className={classes.prodcutsContainer}>
				{console.log(products)}
				{products &&
					products
						.filter((item) => {
							if (item.parentGroup) {
								return item.parentGroup === activeCategory;
							}

							return !activeCategory || item.parentGroup === activeCategory;
						})
						.map((item) => {
							return (
								<div key={item.id} className={classes.productCard}>
									<div className={classes.bannerContentWrap}>
										<div className={classes.imageWrap}>
											<div
												className={classes.image}
												style={{ backgroundImage: `url(${item.images[0]})` }}
											></div>
										</div>
										<div className={classes.titleWrap}>
											<div className={classes.title}>{trim(item.name, 20)}</div>
										</div>
									</div>
									<div className={classes.controlWrap}>
										<div
											className={classes.copyCategory}
											onClick={() => copyText(item.id)}
										>
											<div
												className={`${classes.copyLinkIcon} ${
													copiedId === item.id && classes.copyLinkIconActive
												}`}
											>
												<LinkIcon />{" "}
											</div>
											<div
												className={`${classes.copyLinkText} ${
													copiedId === item.id && classes.copyLinkTextActive
												}`}
											>
												{copiedId === item.id ? "Скопировано" : "Копировать ID"}
											</div>
										</div>
										<div className={classes.eye}>
											<span onClick={() => handleToggleHidden(item.id)}>
												{hiddenProducts.includes(item.id) ? (
													<EyeOffIcon
														style={{ fill: "black", cursor: "pointer" }}
													/>
												) : (
													<EyeIcon
														style={{ fill: "black", cursor: "pointer" }}
													/>
												)}
											</span>
										</div>
									</div>
								</div>
							);
						})}
				{!products && <Loading />}
			</div>
		</div>
	);
};

export default ProductsSort;
